import axios from 'axios';
//报警列表
const alarmlist = (data) => axios.get('/show/safe/alarmList', data);
//报警列表
const WorkOrderList = (data) => axios.get('/show/ops/findAllWorksheet', data);
//客户统计
const getCompanyDevCount = (data) => axios.get('/show/safe/getCompanyDevCount', data);
//异常等级分析
const todayAlarmLevelAlis = (data) => axios.get('/show/safe/todayAlarmLevelAlis', data);
//异常类型分析
const todayAlarmTypeAlis = (data) => axios.get('/show/safe/todayAlarmTypeAlis', data);
//异常趋势
const alarmTrend = (data) => axios.get('/show/safe/alarmTrend', data);
//异常趋势
const iotDev = (data) => axios.get('/show/safe/iotDev', data);
//风险指数-30天
const RIEO = (data) => axios.get('/show/safe/RIEO', data);





export default {

  alarmlist,
  WorkOrderList,
  getCompanyDevCount,
  todayAlarmLevelAlis,
  todayAlarmTypeAlis,
  alarmTrend,
  iotDev,
  RIEO,

}
