<!-- 安全管理 -->
<template>
  <div class="mainbox">
    <div class="left">
      <div class="modulebox">
        <div class="title"><span>客户统计</span></div>
        <div class="content customer">
          <div class="list" v-for="(item,index) in customerlist" :key="index">
            <div class="top"><b>{{item.value}}</b>{{item.dw}}</div>
            <div class="text">{{item.title}}</div>
          </div>
        </div>
      </div>
      <div class="modulebox">
        <div class="title"><span>异常等级分析</span></div>
        <div class="content grade">
          <div class="numbers">
            <span>今日报警</span>
            <div class="box">
              <label v-for="(e,i) in todayalarm" :key="i" >{{e}}</label>
            </div>
          </div>
          <div class="right">
            <div ref="pie1" class="echarts"></div>
          </div>
        </div>
      </div>
      <div class="modulebox">
        <div class="title"><span>异常类别分析</span></div>
        <div class="content category">
          <div class="list" v-for="(item,index) in categorylist" :key="index">
            <div class="top"><b>{{item.value}}</b>{{item.dw}}</div>
            <div class="text">{{item.title}}</div>
          </div>
        </div>
      </div>
      <div class="modulebox">
        <div class="title"><span>异常趋势</span></div>
        <div class="content ">
          <div ref="line1" class="echarts"></div>
        </div>
      </div>
    </div>
    <div class="right">
      <div class="modulebox">
        <div class="title"><span>物联网设备统计</span></div>
        <div class="content equipment">
          <div class="list" v-for="(item,index) in equipmentlist" :key='index'>
            <div class="left"><img :src="item.src"></div>
            <div class="right">
              <p>{{item.title}}</p>
              <p><b>{{item.value}}</b>{{item.dw}}</p>
            </div>
          </div>
        </div>
      </div>
      <div class="modulebox">
        <div class="title"><span>风险指数-30天</span></div>
        <div class="content risk">
          <vue-seamless-scroll :data="risklist" :class-option="{limitMoveNum: 7,direction: 1,step: 0.4,}">

            <p class="nodata" v-if="risklist.length==0">暂无数据</p>
            <table>
              <tr :title="'风险指数:'+item.number*10" v-for="(item,index) in risklist" :key="index">
                <td>{{item.name}}</td><td>{{item.address}}</td>
                <td><div ><span  :style="'width:'+item.number*10+'%'"></span><span>{{item.number}}</span></div></td>
              </tr>
            </table>
          </vue-seamless-scroll>

        </div>
      </div>
      <div class="modulebox">
        <div class="title"><span>最近异常</span></div>
        <div class="content error">
          <vue-seamless-scroll :data="errorlist" :class-option="{limitMoveNum: 8,direction: 1,step: 0.4,}">
            <p class="nodata" v-if="errorlist.length==0">暂无数据</p>
            <table>
              <tr v-for="(item,index) in errorlist" :key="index">
                <td>{{item.alarmTime}}</td><td>{{item.companyName}}</td><td>{{item.subDeviceName}}</td>
                <td>{{item.description}}</td>
                <td>
                  <div v-if=' item.alarmStatus == 1' >报警中</div>
                  <div v-if=' item.alarmStatus == 2' >已恢复</div>
                  <div v-if=' item.alarmStatus == 3' >--</div>
                </td>
                <td>{{ errorStatus.find(e=>e.id==item.handleStatus).label}}</td>
              </tr>
            </table>
          </vue-seamless-scroll>


        </div>
      </div>
      <div class="modulebox">
        <div class="title"><span>最近工单</span></div>
        <div class="content workorder">
          <vue-seamless-scroll :data="workorderlist" :class-option="{limitMoveNum: 8,direction: 1,step: 0.4,}">
            <p class="nodata" v-if="workorderlist.length==0">暂无数据</p>
            <table>
              <tr v-for="(item,index) in workorderlist" :key="index">
                <td>{{item.buildTime}}</td><td>{{item.companyName}}</td><td>{{item.essentials}}</td>
                <td>{{item.chargeUser}}</td><td>{{orderstatus[item.status]}}</td>
              </tr>
            </table>
          </vue-seamless-scroll>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
import api from '@/apis/SaveMangement/index'
import vueSeamlessScroll from 'vue-seamless-scroll'
let echarts = require('echarts');
export default {
  components:{
    vueSeamlessScroll,
  },
  data() {
    return {
      customerlist:[
        {id:0,title:'服务企业数',dw:'家',value:0},
        {id:1,title:'运维设备数',dw:'台',value:0},
        {id:2,title:'服务城市',dw:'个',value:0},
        {id:3,title:'服务行业',dw:'个',value:0},
        {id:4,title:'服务产业',dw:'个',value:0},
      ],
      //异常等级分析
      todayalarm:[0],
      categorylist:[
        {id:0,title:'三相不平衡',dw:'次',value:0},
        {id:1,title:'电压超高',dw:'次',value:0},
        {id:2,title:'电流过载',dw:'次',value:0},
        {id:3,title:'漏电告警',dw:'次',value:0},
        {id:4,title:'其他告警',dw:'次',value:0},
      ],
      equipmentlist:[
        {id:0,title:'电流监测',dw:'台',value:'0',src:require('../../assets/images/a1.png')},
        {id:1,title:'环境监测',dw:'台',value:'0',src:require('../../assets/images/a2.png')},
        {id:2,title:'温度监测',dw:'台',value:'0',src:require('../../assets/images/a3.png')},
        {id:3,title:'网关',dw:'台',value:'0',src:require('../../assets/images/a4.png')},
      ],
      risklist:[
        // {id:0,name:'指尖秘码科技有限公司',address:'负一楼配电室-2号变压器',number:'9.9'},
      ],
      errorStatus: [
        { id: '0', label: '未确认' },
        { id: '1', label: '已确认' },
        { id: '2', label: '已派单' },
        { id: '3', label: '已处理' },
        { id: '4', label: '已自动恢复' },
      ],
      errorlist:[
        // {id:0,time:'21-12-15 14:25:36',name:'指尖秘码科技有限公司',address:'负一楼配电室-2号变压器',type:'电流过高',state:'报警中',state1:'已派单'},

      ],
      orderstatus: [
        '待分配', //0
        '待接单', //1
        '已接单', //2
        '施工中', //3
        '拒绝接单', //4
        '申请延期', //5
        '延期成功', //6
        '拒绝延期', //7
        '逾期', //8
        '待审核', //9
        '待评价', //10
        '审核不通过', //11
        '已完成', //12
        '申请取消', //13
        '取消成功', //14
      ],      
      workorderlist:[
        // {id:0,time:'21-12-15 14:25:36',name:'指尖秘码科技有限公司',address:'处理报警：负一楼配电室-2号变压器',user:'邓维',state:'处理中'},

      ],
      //异常趋势
      line1series:[{
          name: '三相不平衡', type: 'line',showSymbol: false,
          lineStyle: { normal: { width: 3 }},
          data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },{
          name: '功率因素过低', type: 'line',showSymbol: false,
          lineStyle: { normal: { width: 3 }},
          data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },{
          name: '电压不足', type: 'line',showSymbol: false,
          lineStyle: { normal: { width: 3 }},
          data:[0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },{
          name: '电压超高', type: 'line',showSymbol: false,
          lineStyle: { normal: { width: 3 }},
          data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },{
          name: '电流超负荷', type: 'line',showSymbol: false,
          lineStyle: { normal: { width: 3 }},
          data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
      ],

      line1Chart:null,
      currentIndex:-1,

    };
  },

  methods:{
    line1(){
      this.line1Chart = echarts.init(this.$refs.line1);
			this.line1Chart.setOption({
        color:['#00ffcc','#00ccff','#ffff00','#eb6098','#b486f3'],
        tooltip: {
          trigger: 'axis', backgroundColor:'rgba(0,0,0,.8)',borderColor:'#000',
          textStyle:{ color: "#fff",fontSize: 20},
        },
        legend: {
            icon: 'rect',
            itemWidth: 18, itemHeight: 8, itemGap: 30,right: '4%',
            textStyle: {
                fontSize: 20, color: '#fff'
            }
        },
        grid: {
            left: '4%',right: '4%', bottom: '18%', top:'12%'
        },
        xAxis: {
            type: 'category', boundaryGap: false,
            data: ['1月','2月','3月','4月','5月','6月','7月','8月','9月','10月','11月','12月'],
            axisLine: {
              lineStyle: { color: '#3570ff' }
            },
            axisLabel: {  color:'#fff',fontSize: 20,lineHeight:40}
        },
        yAxis: {
            type: 'value',
            axisLine: {
              show:true,
              lineStyle: {
                color: '#3570ff'
              }
            },
            splitLine: { show: false},
            axisLabel: { show:false}
        },
        series: this.line1series
			})
    },
    
    myfun({one= 0,two= 0,three= 0,four=0,total= 0}) {
    



      var myChart = echarts.init(this.$refs.pie1);
      var data = [
        { name: "一级报警", value: parseInt(one/total*100), gdp:one},//value为百分比（取整）,gdp为值
        { name: "二级报警", value: parseInt(two/total*100), gdp:two},
        { name: "三级报警", value: parseInt(three/total*100), gdp:three},
        { name: "四级报警", value: parseInt(four/total*100), gdp:four},
      ];
      var titleArr = [];
      var seriesArr = [];
      var colors = [
        ["#b486f3", "#2b3473"],["#eb6098", "#2b3473"],["#00ffcc", "#2b3473"],
        ["#005be3", "#2b3473"]
      ];
      data.forEach( (item, index) =>{
        titleArr.push({
          text: item.name,
          left: index * 25 + 10 +'%',
          top: '70%',textAlign: "center",
          textStyle: {
            fontWeight: "normal",fontSize: 24,color:'#ccd0f2',textAlign: "center"},
          },
          {
          text: item.gdp,
          left: index * 25 + 10 +'%',
          top: '27%',textAlign: "center",
          textStyle: {
            fontWeight: 600,fontSize: 34,color:'#fff',textAlign: "center"},
          },
          {
          text: '次',
          left: index * 25 + 10 +'%',
          top: '42%',textAlign: "center",
          textStyle: {
            fontWeight: "normal",fontSize: 20,color:'#ccd0f2',textAlign: "center"},
          }
        );
        seriesArr.push({
          type: "pie",
          clockwise: false,
          radius: ['48%', '58%'],
          itemStyle: {
            normal: {
              color: colors[index][0],
              shadowColor: colors[index][0],
              shadowBlur: 0,
              label: {
                show: false,
              },
              labelLine: {
                show: false,
              },
            },
          },
          hoverAnimation: false,
          center: [index * 25 + 10 +'%', '36%'],
          data: [
            {
              value: 100 - item.value,
              name: "invisible",
              itemStyle: {
                normal: {
                  color: colors[index][1],
                },
                emphasis: {
                  color: colors[index][1],
                },
              },
            },
            {
              value: item.value,
              itemStyle: {
                normal: {
                    color: colors[index][0],
                    borderWidth:6,
                    borderColor:colors[index][0],
                },
                emphasis: {
                    color: colors[index][0],
                }
              }
            },
          ],
        });
      });
      var option = {
        title: titleArr,
        series: seriesArr,
      };
      myChart.setOption(option);
    },

  },
  mounted() {
    this.line1();
    this.myfun({one:0,two: 0,three: 0,four:0,total: 10});//异常等级分析

    setInterval( () =>{
      var dataLen = 12;
      // 取消之前高亮的图形
      this.line1Chart.dispatchAction({
        type: 'downplay',
        seriesIndex: 0,
        dataIndex: this.currentIndex
      });
      this.currentIndex = (this.currentIndex + 1) % dataLen;
      //console.log(this.currentIndex);
      // 高亮当前图形
      this.line1Chart.dispatchAction({
        type: 'highlight',
        seriesIndex: 0,
        dataIndex: this.currentIndex
      });
      // 显示 tooltip
      this.line1Chart.dispatchAction({
        type: 'showTip',
        seriesIndex: 0,
        dataIndex: this.currentIndex
      });
    }, 2000);

    api.alarmlist().then(res=>{
      if (res.code===10000) {
        this.errorlist = res.data.list
      } else {
        this.$message.error(res.message)
      }
    })
    api.WorkOrderList().then(res=>{
      if (res.code===10000) {
        this.workorderlist = res.data.list
      } else {
        this.$message.error(res.message)
      }
    })
    //客户统计
    api.getCompanyDevCount().then(res=>{
      if (res.code===10000) {
        this.customerlist[0].value = res.data.company
        this.customerlist[1].value = res.data.devSize
        this.customerlist[2].value = res.data.city
        this.customerlist[3].value = res.data.trade
        this.customerlist[4].value = res.data.service
      } else {
        this.$message.error(res.message)
      }
    })
    //异常等级分析
    api.todayAlarmLevelAlis().then(res=>{
      if (res.code===10000) {
        this.todayalarm = res.data.total.toString().split('')
        this.myfun(res.data);
      } else {
        this.$message.error(res.message)
      }
    })
    //异常类型分析
    api.todayAlarmTypeAlis().then(res=>{
      if (res.code===10000) {
        this.categorylist=[
          {id:0,title:'三相不平衡',dw:'次',value:res.data['三相不平衡']},
          {id:1,title:'电压超高',dw:'次',value:res.data['电压超高']},
          {id:2,title:'电流过载',dw:'次',value:res.data['电流超负荷']},
          {id:3,title:'功率因素过低',dw:'次',value:res.data['功率因素过低']},
          {id:4,title:'其他告警',dw:'次',value:res.data['其他']},
        ]
      } else {
        this.$message.error(res.message)
      }
    })

    //异常趋势
    api.alarmTrend().then(res=>{
      if (res.code===10000) {
        var daraArr = ['01','02','03','04','05','06','07','08','09','10','11','12']
        var resArr = []
        var _data = []
        for (const key in res.data) {
          var obj = {}
          for (const iterator of res.data[key]) {
            obj[iterator.description]= iterator.alarm_count
          }
          obj.month = key
          _data.push(obj);
          resArr.push(key)
        }
        let diff = daraArr.filter( (val) =>{ return resArr.indexOf(val) === -1 })
        for (const key of diff) {
          _data.push({
            month:key
          });
        }
        _data.sort((a, b)=> {
          return a.month - b.month;
        });
        for (const key in _data) {
          this.line1series[0].data[key] = _data[key]['三相不平衡']? _data[key]['三相不平衡']:0
          this.line1series[1].data[key] = _data[key]['功率因素过低']? _data[key]['功率因素过低']:0
          this.line1series[2].data[key] = _data[key]['电压不足']? _data[key]['电压不足']:0
          this.line1series[3].data[key] = _data[key]['电压超高']? _data[key]['电压超高']:0
          this.line1series[4].data[key] = _data[key]['电流超负荷']? _data[key]['电流超负荷']:0

        }        
        this.line1();
      } else {
        this.$message.error(res.message)
      }
    })
    //物联网设备统计
    api.iotDev().then(res=>{
      if (res.code===10000) {
        this.equipmentlist[0].value = res.data.power
        this.equipmentlist[1].value = res.data.environment
        this.equipmentlist[2].value = res.data.temperature
        this.equipmentlist[3].value = res.data.gatWay
      } else {
        this.$message.error(res.message)
      }
    })
    //风险指数-30天
    api.RIEO().then(res=>{
      if (res.code===10000) {
        for (const iterator of res.data) {
          var number= 0
          var name = ''
          if (iterator.data) {
            name = iterator.data.devName
          }
          if (iterator.data.infos) {
            number = iterator.data.infos[0].score
          }
          number=parseInt(number*100)
          this.risklist.push({
            name:iterator.companyName,
            address:name,
            number:number
          })          
        }
      } else {
        this.$message.error(res.message)
      }
    })



  },
  beforeDestroy(){
    //清除所有定时器
    let _end = setInterval(function(){},2000);
    for(let i =1;i<=_end;i++){
      clearInterval(i)
    }
  },  
}
</script>

<style lang="less" scoped>
.nodata{
  text-align: center;

}
.mainbox{ position: absolute; width: 100%; height: 100%; top:0; left:0; padding:70px 60px;
  overflow: hidden;
  &>.left{ width: 1360px; height: 2020px; position: absolute; z-index: 8; top:70px; left:60px;
    .modulebox{ flex: auto;}
    .modulebox:nth-child(1){ height: 14rem; flex-shrink: 0; margin-top: 0;}
    .modulebox:nth-child(2){ height: 14rem; flex-shrink: 0;}
    .modulebox:nth-child(3){ height: 14rem; flex-shrink: 0;}
    .modulebox:nth-child(4){ height: 24rem; flex-shrink: 0;}
  }
  &>.right{ width: 1360px; height: 2020px; display: flex; flex-direction: column; float: right;
    .modulebox:nth-child(1){ height: 10rem; flex: inherit; margin-top: 0;}
    .modulebox:nth-child(2){ height: 23rem; flex: inherit;}
    .modulebox{ flex-shrink: 0; overflow: hidden;}
  }

  .customer{ display: flex; flex-direction: row; padding:0 .6rem .6rem;
    .list{ flex: 1; margin:.5rem; border-radius: 10px; border: #005ae4 solid 1px; overflow: hidden; display: flex; flex-direction: column;
      .top{ flex: 1; display: flex; flex-direction: column; align-items: center; justify-content: center;
        b{ font-size: 1.8rem; font-weight: 500; line-height: 1; color: #00ffcc;}
      }
      .text{ height: 3rem; line-height: 3rem; color: #fff; text-align: center; background: #05238f;}
    }
  }
  .grade{ display: flex; flex-direction: row;
    .numbers{ width: 14rem; text-align: center; display: flex; flex-direction: column;
      span{ color: #fff; font-size: 1rem; color: #00ffcc; margin-top: 2rem;}
      .box{ margin-top: 1rem;
        label{ display: inline-block; padding:0 4px; margin-right: 8px; border: #005be3 solid 1px; background: rgba(0,0,0,.2); line-height: 2.2rem; box-shadow: 0 0 6px #00ccff; font-size: 2rem; font-weight: 600; color: #ffff00;}
      }
    }
    .right{ flex: 1;}
  }
  .category{ display: flex; flex-direction: row; padding:.4rem 1rem 1rem;
    .list{ flex: 1; margin:.5rem; border-radius: 10px; overflow: hidden; display: flex; flex-direction: column; align-items: center;
      .top{ width: 6.6rem; height: 6.6rem; position: relative; border-radius: 50%; border: #005ae4 solid 6px; display: flex; flex-direction: column; align-items: center; justify-content: center;
        b{ font-size: 1.8rem; font-weight: 500; color: #eb6098; line-height: 1; padding-top: 15px;}
      }
      .top::after{ content:' '; position: absolute; bottom: 0; width: 0; height: 0; border-style: solid; border-width: 16px; border-color: transparent transparent #eb6098 transparent;}
      .text{ height: 3rem; line-height: 2.2rem; color: #fff;}
    }
  }
  .equipment{ display: flex; flex-direction: row; padding-bottom:.4rem;
    .list{ width: 25%; display: flex; flex-direction: row; align-items: center;
      .left{ width: 4.4rem; height: 4.4rem; margin-right: .4rem; flex-shrink: 0; margin-left: 1rem;
        img{ width: 100%; height: 100%;}
      }
      .right{ display: flex; flex-direction: column; justify-content: center;
        b{ font-size: 2.6rem; font-weight: 500; margin-right: 10px; font-family: 'DS-DIGIT';}
      }
    }
    .list:nth-child(1) p:nth-child(2){ color: #eb6098;}
    .list:nth-child(2) p:nth-child(2){ color: #b486f3;}
    .list:nth-child(3) p:nth-child(2){ color: #ffff00;}
    .list:nth-child(4) p:nth-child(2){ color: #00ffcc;}
  }
  .risk{ padding:.4rem 1rem 1rem;
    table{ width: 100%;  border-collapse: collapse; table-layout: fixed;
      tr{
        height: 3em;
        td{ border: #005ae4 dashed 1px; padding-left: 15px; white-space:nowrap; overflow:hidden; text-overflow:ellipsis;}
        td:nth-child(3){ width: 50%;
          div{ padding-right: 60px; position: relative;
            span:nth-child(1){ display: inline-block; background: #f29149; height: 25px;}
            span:nth-child(2){ position: relative; left: 10px; top:-2px;}
          }
        }
      }
    }
  }
  .error,.workorder{ padding:.4rem 1rem 1rem; z-index: 9; overflow: hidden;
    table{ width: 100%;  border-collapse: collapse;
      tr{ cursor: pointer;
        height: 3em;
        td{ white-space:nowrap; overflow:hidden; text-overflow:ellipsis;}
      }
      tr:nth-child(odd){ background: rgba(0,204,255,.1);}
      td:nth-child(1){ padding-left: 10px;}
      tr:hover td{ color: #00ffcc;}
    }
  }



}
</style>
